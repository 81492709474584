<template>
  <div class="pf-content-freelance">
    <a class="pf-content-freelance__link"
       target="_blank"
       rel="noopener"
       :href="item.href">
      <span class="pf-content-freelance__text f-h2 f-uppercase"
            :class="textClasses"
            v-html="item.content"></span>

      <span v-if="!isMobileDevice"
            class="pf-content-freelance__text-hover f-h2 f-uppercase"
            :class="textHoverClasses"
            v-html="item.content"></span>
    </a>
  </div>
</template>

<script>
  // Store
  import { GETTERS as G } from '@/store/helpers'

  export default {
    name: 'PFContentFreelance',

    props: {
      item: {
        type: Object,
        required: true
      }
    },

    computed: {
      textClasses () {
        return [
          this.item.color ? `f-color-${this.item.color}` : ''
        ]
      },

      textHoverClasses () {
        return [
          this.item.color ? `u-bg-color--${this.item.color}` : '',
          'f-color-brand-black'
        ]
      },

      isMobileDevice () {
        return this.$store.getters[G.isMobileDevice]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pf-content-freelance {
    display: flex;
    justify-content: center;

    text-align: center;
  }

  .pf-content-freelance__link {
    position: relative;
  }

  .pf-content-freelance__text {
    padding-right: 20px;
    padding-left: 20px;

    font-feature-settings: "kern" on,"liga" on,"rlig" on,"calt" on;
    font-variation-settings: "slnt" 0, "wdth" 900, "wght" 700;

    @include breakpoint('small-') {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .pf-content-freelance__text-hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);

    width: 100%;
    height: 100%;
    padding-right: 20px;
    padding-left: 20px;

    font-feature-settings: "kern" on,"liga" on,"rlig" on,"calt" on;
    font-variation-settings: "slnt" 0, "wdth" 900, "wght" 700;

    opacity: 0;
    visibility: hidden;

    will-change: opacity, visibility;

    .pf-content-freelance__link:hover & {
      opacity: 1;
      visibility: visible;
    }
  }
</style>
