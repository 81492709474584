<template>
  <div class="phome u-bg-color--brand-black">
    <div class="phome__screen-container">
      <pf-content-centered :content="content.lines">
      </pf-content-centered>

      <pf-content-freelance :item="content.freelance">
      </pf-content-freelance>
    </div>
  </div>
</template>

<script>
  // Mixins
  import PageMixin from '@/mixins/page'

  // Components
  import PFContentCentered from '@/components/content/PFContentCentered'
  import PFContentFreelance from '@/components/content/PFContentFreelance'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'

  export default {
    name: 'PHome',
    pageName: 'home',
    mixins: [PageMixin],

    components: {
      'pf-content-centered': PFContentCentered,
      'pf-content-freelance': PFContentFreelance
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
    },

    mounted () {
      this._onResize()
    },

    detroyed () {
      // Events
      EventBus.$off('resize', this._onResize)
    },

    methods: {
      // Events
      _onResize () {
        let vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .phome {
    position: relative;
    min-height: 100vh;
  }

  .phome__screen-container {
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: 20px;
  }
</style>
