<template>
  <section class="pf-content-centered">
    <div class="pf-content-centered__container">
      <pf-content-line v-for="(item, itemIndex) in content"
                     :key="`pf-content-line_${itemIndex}`"
                     :item="item">
    </pf-content-line>
    </div>
  </section>
</template>

<script>
  // Components
  import PFContentLine from './PFContentLine'

  export default {
    name: 'PFContentCentered',

    components: {
      'pf-content-line': PFContentLine
    },

    props: {
      content: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pf-content-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .pf-content-centered__container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 12,
      xlarge: 12
    ));
    max-width: $max-width;
  }
</style>
