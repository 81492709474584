// Mixins
import BaseMixin from '@/mixins/base'
import ImagesMixin from '@/mixins/images'

// Data
import pageData from '@/data/page'

// Helpers
import { decode } from '@/helpers/string'
import { getLang } from '@/helpers/lang'

const LANG = getLang()

export default {
  pageName: 'home',

  mixins: [BaseMixin, ImagesMixin],

  computed: {
    page () {
      return pageData(this.$options.pageName)
    },

    meta () {
      return this.page.meta
    },

    content () {
      return this.page.content
    }
  },

  head: {
    title: function () {
      return {
        inner: decode(this.page.meta.title)
      }
    },

    meta: function () {
      return [
        { p: 'description', c: decode(this.page.meta.description) },
        // OpenGraph
        { p: 'og:title', c: process.env.VUE_APP_SITE_NAME },
        { p: 'og:site_name', c: decode(this.page.meta.title) },
        { p: 'og:description', c: decode(this.page.meta.description) },
        { p: 'og:image', c: `${LANG === 'fr-FR' ? process.env.VUE_APP_URL_FR : process.env.VUE_APP_URL_EN}${this.allImages['seo/floriancordillot_1200x630'].formats.jpg.src}` },
        { p: 'og:image:alt', c: decode(this.page.meta.title) },
        { p: 'og:url', c: LANG === 'fr-FR' ? process.env.VUE_APP_URL_FR : process.env.VUE_APP_URL_EN },
        // Twitter
        { p: 'twitter:title', c: process.env.VUE_APP_SITE_NAME },
        { p: 'twitter:description', c: decode(this.page.meta.description) },
        { p: 'twitter:image', c: `${LANG === 'fr-FR' ? process.env.VUE_APP_URL_FR : process.env.VUE_APP_URL_EN}${this.allImages['seo/floriancordillot_900x450'].formats.jpg.src}` },
        // Favicon
        { p: 'msapplication-TileColor', c: '#ffffff' },
        { p: 'msapplication-TileImage', c: this.allImages['seo/ms-icon-144x144'].formats.png.src },
        { p: 'theme-color', c: '#ffffff' }
      ]
    },

    link: function () {
      return [
        // Favicon
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-57x57'].formats.png.src, sizes: '57x57' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-60x60'].formats.png.src, sizes: '60x60' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-72x72'].formats.png.src, sizes: '72x72' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-76x76'].formats.png.src, sizes: '76x76' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-114x114'].formats.png.src, sizes: '114x114' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-120x120'].formats.png.src, sizes: '120x120' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-144x144'].formats.png.src, sizes: '144x114' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-152x152'].formats.png.src, sizes: '152x152' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-180x180'].formats.png.src, sizes: '180x180' },
        { rel: 'icon', href: this.allImages['seo/android-icon-192x192'].formats.png.src, sizes: '192x192', type: 'image/png' },
        { rel: 'icon', href: this.allImages['seo/favicon-32x32'].formats.png.src, sizes: '32x32', type: 'image/png' },
        { rel: 'icon', href: this.allImages['seo/favicon-96x96'].formats.png.src, sizes: '96x96', type: 'image/png' },
        { rel: 'icon', href: this.allImages['seo/favicon-16x16'].formats.png.src, sizes: '16x16', type: 'image/png' }
      ]
    }
  },

  beforeMount () {
    document.body.setAttribute('data-page', this.$route.name)
  }
}
