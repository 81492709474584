export default () => {
  return {
    meta: {
      title: '',
      description: 'I\'m Florian, a Freelance Developer. Say hi to: florian.cordillot@gmail.com'
    },

    content: {
      lines: [{
        content: 'Florian',
        color: 'brand-purple'
      }, {
        content: 'is a freelance',
        color: 'brand-green'
      }, {
        content: 'developer',
        color: 'brand-orange'
      }, {
        content: 'based in paris',
        color: 'brand-pink'
      }],
      freelance: {
        content: 'Available for freelance',
        href: 'mailto:florian.cordillot@gmail.com',
        color: 'brand-yellow'
      }
    }
  }
}
