<template>
  <div class="app">
    <pf-loader ref="loader"></pf-loader>

    <router-view ref="page" />
  </div>
</template>

<script>
  // Store
  import { GETTERS as G, MUTATIONS as M, STATE as S } from '@/store/helpers'

  // Behavior
  import ScrollRAF from '@/behavior/scrollRAF'

  // Helpers
  import { isAutoplaySupportedOldBrowser } from '@/helpers/videos'
  import { throttle } from '@/helpers/globals'
  import { enableScroll } from '@/helpers/scroll'
  import { EventBus } from '@/helpers/event-bus'

  // Components
  import PFLoader from '@/components/loader/PFLoader'

  export default {
    components: {
      'pf-loader': PFLoader
    },

    data () {
      return {
        env: process.env.VUE_APP_ENV,
        error: null,
        loading: true
      }
    },

    computed: {
      windowH () {
        return this.$store.state[S.windowH]
      },

      isMobileDevice () {
        return this.$store.getters[G.isMobileDevice]
      },

      support () {
        return this.$store.getters[G.support]
      }
    },

    beforeCreate () {
      isAutoplaySupportedOldBrowser().then(() => {
        this.$store.commit(M.autoplayVideo, true)
      }).catch(() => {
        this.$store.commit(M.autoplayVideo, false)
      })
    },

    created () {
      this._updateBodyClass()
    },

    mounted () {
      enableScroll()

      EventBus.$emit('PFLoader:hide')

      this.$store.commit(M.mediaQuery)
      window.addEventListener('resize', this.onResize)
      this.onResize()
    },

    beforeDestroy () {
      window.removeEventListener('resize', this.onResize)
      document.removeEventListener('app:back-to-top', this.handleBackToTop, false)
    },

    methods: {
      // BODY
      _updateBodyClass () {
        const $body = document.body

        if ($body && this.support.classes.length > 0) {
          this.support.classes.map(c => $body.classList.add(c))

          $body.classList.add(`color-scheme--${this.$store.getters[G.colorScheme]}`)
        }
      },

      scrollRafCallback (event) {
        this.toggleVisibilityTracker(event.scrollY)
      },

      toggleVisibilityTracker (scroll) {
        this.menuIsShown = scroll >= this.showMenuOffset
      },

      startRaf () {
        if (this.scrollRafID) return
        this.$nextTick(() => {
          this.scrollRafID = ScrollRAF.add(this.scrollRafCallback)
        })
      },

      stopRaf () {
        if (this.scrollRafID) this.scrollRafID = ScrollRAF.remove(this.scrollRafID)
      },

      onResize: throttle(function () {
        EventBus.$emit('resize')

        this.$store.commit(M.mediaQuery)
        this.$store.commit(M.windowH)
      }, 200)
    }
  }
</script>

<style lang="scss">
  @import "scss/app";

  html,
  body {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  #main + footer {
    background-color: #FFF;
  }
</style>

<style lang="scss" scoped>
  .app {
    width: 100vw;
    min-height: 100vh;
  }
</style>
