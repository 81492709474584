<template>
  <div class="pf-content-line">
    <span class="pf-content-line__text f-h1 f-uppercase"
          :class="textClasses"
          v-html="item.content"></span>
  </div>
</template>

<script>
  export default {
    name: 'PFContentLine',

    props: {
      item: {
        type: Object,
        required: true
      }
    },

    computed: {
      textClasses () {
        return [
          this.item.color ? `f-color-${this.item.color}` : ''
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pf-content-line {
    text-align: center;
  }

  .pf-content-line__text {
    font-feature-settings: "kern" on,"liga" on,"rlig" on,"calt" on;
    font-variation-settings: "slnt" 0, "wdth" 900, "wght" 700;
  }
</style>
